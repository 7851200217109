import 'require.js';
import 'requirejs.patch.js';

import 'elu.js';
import 'elu.patch.js';

import 'elu_w2ui.js';
import 'energy.js';

import 'handler.js';
import 'handler.patch.js';

/* files */
import 'favicon.ico';
import 'robots.txt';

/* live reload ( replace string on demand, see webpack config ) */

